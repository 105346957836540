import React from 'react'
import Layout from '../../components/Layout'
import Juka50Styles from './Juka50.module.css'
import MvTextPageHighlight from '../../components/common/MvTextPageHighlight'

export default () => {
  return (
    <Layout header="<p>hello, header</p>">
      <div className={Juka50Styles.header}>
        <img className={Juka50Styles.light} src="/images/juka50/lights.svg" />
        <img
          className={Juka50Styles.logo}
          src="/images/juka50/50JahreJuka.svg"
        />
        <div className={Juka50Styles.intro}>
          <h2>Die JUKA feiert 50 Jahre!</h2>
          <p>
            Unserer Jugendkapelle des Musikverein Trachtenkapelle Winterbach
            feiert dieses Jahr ihr 50-jähriges Bestehen. Durch den Abend führt
            ein buntes Programm verschiedener Musikgruppen. Für eine großartige
            Partystimmung und grandiose Verpflegung ist der Musikverein bekannt.
            Ein toller Abend mit Barbetrieb wartet auf euch am 20.04.2024 ab
            18:00 Uhr. Wir freuen uns auf einen tollen Abend!
          </p>
          <p>
            <i>Euer Jugendleitungsteam</i>
          </p>
        </div>
        <div className={Juka50Styles.info}>
          <span className={Juka50Styles.date}>20. April 2024</span>
          <span>Lehenbachhalle Winterbach</span>
          <div>
            <mark>Einlass ab 18 Uhr • Eintritt frei!</mark>
          </div>
        </div>
      </div>
      <div className={Juka50Styles.main}>
        <div className={Juka50Styles.agenda}>
          <h2>Programm</h2>
          <img src="/images/juka50/balloons.svg" />
        </div>

        <div className={Juka50Styles.act}>
          <span className={Juka50Styles.actDate}>19:00 Uhr</span>
          <span className={Juka50Styles.actTitle}>Jugendkapelle</span>
          <p>
            Die Jugendkapelle des Musikverein Trachtenkapelle Winterbach gibt es
            bereits seit 1974. Von Karl Nachtrieb (Vetter) über Richard Keil bis
            hin zu Andreas Ziegenhorn haben viele verschiedene großartige
            Musiker die Jugendkapelle dirigiert. Dominik Dörner und Jörg Krohmer
            wurden von Sonja Nachtrieb 2015 abgelöst. Sonja dirigiert unsere
            Jugendkapelle nun schon seit knapp 9 Jahren! Für diesen besonderen
            Tag haben sich die Kinder die Stücke selbst ausgewählt und ein
            Programm mithilfe ihrer Dirigentin erstellt. Die Liedauswahl
            beinhaltet eine kunterbunte Mischung aus Charts, Rock und Pop.
          </p>
        </div>

        <div className={Juka50Styles.act}>
          <span className={Juka50Styles.actDate}>20:30 Uhr</span>
          <span className={Juka50Styles.actTitle}>JUKA reloaded</span>
          <p>
            Zum 50-jährigen Jubiläum der Jugendkapelle des Musikverein
            Trachtenkapelle Winterbach, haben einige ehemalige Juka Mitglieder
            beschlossen, eine Ehemaligenband für diesen besonderen Anlass zu
            gründen. Von Schlager über Rock bis hin zu richtig alten Schinken
            haben wir viele tolle Juka-Klassiker aus den Schränken gezaubert.
            Mit viel Spaß und Elan proben ca. 45 Musiker seit Oktober 2023 an
            den Stücken.
          </p>
        </div>

        <div className={Juka50Styles.act}>
          <span className={Juka50Styles.actDate}>22:00 Uhr</span>
          <span className={Juka50Styles.actTitle}>Jimmy and the Gang</span>
          <p>
            Für alle, die uns noch nicht kennen: Wir sind eine kleine soziale
            Einheit zum Zwecke der harmonischen Klangbildung auf Basis
            mehrstimmiger Notengestaltung in einträchtigem Zusammenspiel mit
            rhythmusgebenden Klangkörpern unter Zuhilfenahme von
            lautstärkespendenden Klein- und Großanlagen.
          </p>
          <p>
            Man könnte auch sagen, wir sind ein Chor, in dem nicht jeder singen
            darf dafür aber der eine oder andere ein Instrument umschnallt, um
            sich von der singenden Fraktion abzuheben. Im Prinzip machen wir
            alles, was Spass macht.
          </p>
          <p>
            In unserem musikalischen Repertoire sind Titel der
            unterschiedlichsten Musikrichtungen: Schlager wie „Eine neue Liebe“
            von Jürgen Marcus, Rock Klassiker wie „Doctor Doctor“ von UFO
            Balladen wie „One“ von U2 und auch Pop Klassiker wie „Angels“ von
            Robbie Williams werden bei uns gehört und gespielt.
          </p>
          <p>
            An manchen Abenden kanns auch etwas bluesig werden, wenn unser
            Basser das Kommando übernimmt und zu Dieters Skat-Blues aufspielt.
            Danach gehts dann weiter mit Rock 'n' Roll - Titel à la „Johnny B.
            Goode“ oder „Rockin´ all over the World“.
          </p>
        </div>
      </div>

      <MvTextPageHighlight
        title="Sie haben noch Fragen?"
        subtitle="Kontaktieren Sie uns"
        action="jugendleitung"
        className={Juka50Styles.contact}
      >
        Melden Sie sich gerne bei unserer Jugendleitung (Sabrina Girschek und
        Alina Lederer).
      </MvTextPageHighlight>
    </Layout>
  )
}
